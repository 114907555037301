<template>
  <div class="form">
    <div class="logo">
      <logo />
    </div>
    <div class="title is-3-5 mb-1">Восстановить пароль</div>

    <div v-if="errors" class="errors is-size-7-2">
      <p
        v-for="(error, field) in errors"
        :key="field"
        class="mb-3 has-text-danger"
      >
        {{ error[0] }}
      </p>
    </div>

    <div class="restore-form mt-2" v-if="!isSuccess">
      <form v-on:submit.prevent="recover">
      <tnd-input class="form__input" placeholder="Ваш email" v-model="email">
        <template #before-input>
          <user-icon />
        </template>
      </tnd-input>
      <button
        @click.prevent="recover"
        class="button is-normal main-button purple"
      >
        <span class="icon is-small">
          <locker-icon color="#FFFFFF" />
        </span>
        <span>Напомнить пароль</span>
      </button>
      </form>
      <div class="back-to-link">
        <span class="common-text">Уже есть аккаунт?&nbsp;</span>
        <router-link to="/login" class="common-link">
          Войдите в систему
        </router-link>
      </div>
    </div>
    <div class="restore-success" v-else>
      <p class="common-text">Успешно!</p>
      <p class="common-text">Новый пароль отправлен на почту.</p>
      <p class="common-text">Проверьте Вашу почту и войдите в систему.</p>
      <router-link to="/login" class="button is-normal main-button purple">
        <span class="icon is-small">
          <user-icon color="#FFFFFF" />
        </span>
        <span>Войти в систему</span>
      </router-link>
      <div class="back-to-link">
        <span class="common-text">Не зарегистрированы? &nbsp;</span>
        <router-link to="sign-up" class="common-link">
          Пройдите регистрацию
        </router-link>
      </div>
    </div>

    <router-link to="/" class="common-link form__link">
      Перейти на главную
    </router-link>
  </div>
</template>

<script>
import LockerIcon from "../icons/LockerIcon.vue";
import Logo from "../icons/Logo.vue";
import UserIcon from "../icons/UserIcon.vue";
import TndInput from "./TndInput.vue";
import authService from "../../services/auth.service";

export default {
  components: { Logo, TndInput, LockerIcon, UserIcon },
  data: () => ({
    isSuccess: false,
    email: "",
    errors: null,
  }),
  methods: {
    async recover() {
      this.errors = null;
      
      const data = await authService.recover(this.email);
      const {success, message, errors} = data;

      if (success) {
         this.$buefy.toast.open({
          message: message,
          type: "is-success",
          duration: 4000
        });

        this.$router.push({ name: "login" });
      } else {
        this.errors = errors;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  min-width: 50%;
  display: flex;
  flex-direction: column;

  .restore-form,
  .restore-success {
    display: flex;
    flex-direction: column;
  }

  .title {
    margin-top: 75px;
  }

  &__input:first {
    margin-top: 28px;
  }

  &__input + &__input {
    margin-top: 6px;
  }

  .button {
    margin-top: 32px;
  }

  .back-to-link {
    display: flex;
    margin-top: 29px;
  }

  &__link {
    margin-top: 77px;
  }
}
</style>
