<template>  
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.8439 5.375C11.7061 7.23371 10.297 8.75 8.75015 8.75C7.20328 8.75 5.79175 7.23406 5.6564 5.375C5.51578 3.44141 6.88687 2 8.75015 2C10.6134 2 11.9845 3.47656 11.8439 5.375Z" :stroke="color" stroke-width="1.5" stroke-linecap="round" fill="none" stroke-linejoin="round"/>
    <path d="M8.75009 11C5.69149 11 2.58719 12.6875 2.01274 15.8727C1.94348 16.2566 2.16075 16.625 2.56259 16.625H14.9376C15.3398 16.625 15.557 16.2566 15.4878 15.8727C14.913 12.6875 11.8087 11 8.75009 11Z" :stroke="color" stroke-width="1.5" fill="none" stroke-miterlimit="10"/>
  </svg>
</template>

<script>

export default {
  name: 'UserIcon',
  props: {
    color: {
      type: String,
      default: '#A01B5B'
    }
  }
}
</script>